const plugin = require('tailwindcss/plugin');

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./app/**/*.{html,js,ts,tsx}', './custom/instance/**/*.html', './app/index.ejs'],
  darkMode: 'class',
  theme: {
    screens: {
      sm: '581px',
      md: '768px',
      lg: '976px',
      xl: '1280px',
      '2xl': '1536px',
    },
    extend: {
      aspectRatio: {
        video: '16 / 9',
        square: '1 / 1',
      },
      boxShadow: ({ theme }) => ({
        '3xl': '0 25px 75px -15px rgba(0, 0, 0, 0.25)',
        'inset-ring': `inset 0 0 0 2px ${theme('colors.accent-blue')}`,
      }),
      fontSize: {
        base: '0.9375rem',
        md: '1rem',
      },
      fontFamily: {
        sans: [
          'Inter',
          'ui-sans-serif',
          'system-ui',
          '-apple-system',
          'BlinkMacSystemFont',
          'Segoe UI',
          'Roboto',
          'Helvetica Neue',
          'Arial',
          'Noto Sans',
          'sans-serif',
          'Apple Color Emoji',
          'Segoe UI Emoji',
          'Segoe UI Symbol',
          'Noto Color Emoji',
        ],
        'mono': [
          'Roboto Mono',
          'ui-monospace',
          'mono',
        ],
      },
      lineClamp: {
        7: '7',
      },
      spacing: {
        '0.75': '0.1875rem',
        '4.5': '1.125rem',
      },
      colors: {
        gray: {
          50: '#fff',
          100: '#fbfafb',
          200: '#f6f6f7',
          300: '#ededef',
          400: '#e5e4e7',
          500: '#d3d2d8',
          600: '#868393',
          700: '#656175',
          800: '#201c3c',
          900: '#08051b',
        },
        primary: {
          100: '#eeedfd',
          200: '#e0e7ff',
          300: '#a5b4fc',
          400: '#818cf8',
          500: '#5448ee',
          600: '#4338ca',
          700: '#2a2477',
          800: '#1d1953',
          900: '#14102f',
        },
        secondary: {
          100: '#ffe0e9',
          200: '#ffb1c8',
          300: '#ff749d',
          400: '#ff5f87',
          500: '#ff4775',
          600: '#ff4774',
        },
        success: {
          50: '#f0fdf4',
          100: '#dcfce7',
          200: '#bbf7d0',
          300: '#86efac',
          400: '#4ade80',
          500: '#22c55e',
          600: '#16a34a',
          700: '#15803d',
          800: '#166534',
          900: '#14532d',
        },
        danger: {
          50: '#fef2f2',
          100: '#fee2e2',
          200: '#fecaca',
          300: '#fca5a5',
          400: '#f87171',
          500: '#ef4444',
          600: '#dc2626',
          700: '#b91c1c',
          800: '#991b1b',
          900: '#7f1d1d',
        },
        'accent-pink': '#ff4774',
        'accent-blue': '#35ccf0',
        'accent-green': '#2feecc',
        'accent-gold': '#ffb661',
        accent: {
          300: '#ff5f87',
          500: '#ff4775',
        },
        'gradient-start': '#b8a3f9',
        'gradient-end': '#9bd5ff',
        'sea-blue': '#2feecc',
      },
      animation: {
        'enter': 'enter 200ms ease-out',
        'leave': 'leave 150ms ease-in forwards',
        'spinner': 'spinner 1.2s linear infinite',
        'progress-bar': 'progress-bar 1s linear infinite',
        'marquee': 'marquee linear infinite',
        'marquee2': 'marquee2 linear infinite',
        'slide-up': 'slide-up 300s linear forwards infinite',
        'slide-down': 'slide-down 300s linear forwards infinite',
      },
      keyframes: {
        'progress-bar': {
          from: { 'background-position': '0.25rem 0' },
          to: { 'background-position': '0 0' },
        },
        enter: {
          from: { transform: 'scale(0.9)', opacity: '0' },
          to: { transform: 'scale(1)', opacity: '1' },
        },
        leave: {
          from: { transform: 'scale(1)', opacity: '1' },
          to: { transform: 'scale(0.9)', opacity: '0' },
        },
        spinner: {
          from: { opacity: 1 },
          to: { opacity: 0 },
        },
        marquee: {
          '0%': { transform: 'translateX(0%)' },
          '100%': { transform: 'translateX(-100%)' },
        },
        marquee2: {
          '0%': { transform: 'translateX(100%)' },
          '100%': { transform: 'translateX(0%)' },
        },
        'slide-down': {
          '0%': { transform: 'translateY(-50%)' },
          '100%': { transform: 'translateY(0)' },
        },
        'slide-up': {
          '0%': { transform: 'translateY(0)' },
          '100%': { transform: 'translateY(-50%)' },
        },
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    require('@tailwindcss/aspect-ratio'),
    plugin(function({ matchUtilities, theme }) {
      matchUtilities(
        {
          'animate-delay': (value) => ({
            animationDelay: value,
          }),
        },
        { values: theme('transitionDelay') },
      );
    }),
  ],
};
