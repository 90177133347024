import"core-js/modules/es.array.filter.js";import"core-js/modules/es.object.to-string.js";import"core-js/modules/es.array.flat-map.js";import"core-js/modules/es.array.unscopables.flat-map.js";import"core-js/modules/es.array.push.js";import{statusSchema}from'soapbox/schemas';import{filteredArray}from'soapbox/schemas/utils';import{QueryKeys}from'../../query-keys';import{importEntities}from'../index';/**
 * Import statuses to the global cache.
 *
 * In each status, there are three possible statuses to import:
 *  - status itself
 *  - "quote" status
 *  - "reblog" status
 *
 * @param statuses - array of Statuses
 */function importStatuses(statuses){var statusesToImport=statuses.flatMap(function(status){return[status,status.reblog,status.quote];}).filter(Boolean);var parsedResult=filteredArray(statusSchema).safeParse(statusesToImport);if(parsedResult.success){importEntities(QueryKeys.statuses.global,parsedResult.data);}}/**
 * Import statuses to the global cache from a list of Notifications.
 *
 * In each status, there are three possible statuses to import:
 *  - status itself
 *  - "quote" status
 *  - "reblog" status
 *
 * @param notifications - array of Notifications
 */function importStatusesFromNotifications(notifications){var statusesToImport=notifications.flatMap(function(notification){var statuses=[];if('status'in notification){var _notification$status,_notification$status2;statuses.push(notification.status);statuses.push((_notification$status=notification.status)===null||_notification$status===void 0?void 0:_notification$status.reblog);statuses.push((_notification$status2=notification.status)===null||_notification$status2===void 0?void 0:_notification$status2.quote);}return statuses;}).filter(Boolean);var parsedResult=filteredArray(statusSchema).safeParse(statusesToImport);if(parsedResult.success){importEntities(QueryKeys.statuses.global,parsedResult.data);}}export{importStatuses,importStatusesFromNotifications};