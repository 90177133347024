import"core-js/modules/es.array.find.js";import"core-js/modules/es.object.to-string.js";/**
 * Attachment normalizer:
 * Converts API attachments into our internal format.
 * @see {@link https://docs.joinmastodon.org/entities/attachment/}
 */import{Map as ImmutableMap,Record as ImmutableRecord,fromJS}from'immutable';import{mergeDefined}from'soapbox/utils/normalizers';// https://docs.joinmastodon.org/entities/attachment/
export var AttachmentRecord=ImmutableRecord({blurhash:undefined,description:'',external_video_id:null,// TruthSocial
id:'',meta:ImmutableMap(),pleroma:ImmutableMap(),preview_url:'',remote_url:null,type:'unknown',url:'',// TV fields
text_url:'',tv:ImmutableMap(),preview_remote_url:'',// Internal fields
// TODO: Remove these? They're set in selectors/index.js
account:null,status:null,width:null,height:null});// Ensure attachments have required fields
var normalizeUrls=function normalizeUrls(attachment){var url=[attachment.get('url'),attachment.get('preview_url'),attachment.get('remote_url'),attachment.get('text_url'),attachment.get('preview_remote_url')].find(function(url){return url;})||'';var base=ImmutableMap({url:url,preview_url:url});return attachment.mergeWith(mergeDefined,base);};// Ensure meta is not null
var normalizeMeta=function normalizeMeta(attachment){var meta=ImmutableMap().merge(attachment.get('meta'));var original=meta.get('original');return attachment.set('meta',meta).set('width',original===null||original===void 0?void 0:original.get('width')).set('height',original===null||original===void 0?void 0:original.get('height'));};export var normalizeAttachment=function normalizeAttachment(attachment){return AttachmentRecord(ImmutableMap(fromJS(attachment)).withMutations(function(attachment){normalizeUrls(attachment);normalizeMeta(attachment);}));};