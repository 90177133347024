import{createSelector}from'reselect';import{getHost}from'soapbox/actions/instance';import{normalizeSoapboxConfig}from'soapbox/normalizers';import KVStore from'soapbox/storage/kv-store';var SOAPBOX_CONFIG_REQUEST_SUCCESS='SOAPBOX_CONFIG_REQUEST_SUCCESS';var SOAPBOX_CONFIG_REQUEST_FAIL='SOAPBOX_CONFIG_REQUEST_FAIL';var SOAPBOX_CONFIG_REMEMBER_REQUEST='SOAPBOX_CONFIG_REMEMBER_REQUEST';var SOAPBOX_CONFIG_REMEMBER_SUCCESS='SOAPBOX_CONFIG_REMEMBER_SUCCESS';var SOAPBOX_CONFIG_REMEMBER_FAIL='SOAPBOX_CONFIG_REMEMBER_FAIL';var getSoapboxConfig=createSelector([function(state){return state.soapbox;}],function(soapbox){return normalizeSoapboxConfig(soapbox);});var rememberSoapboxConfig=function rememberSoapboxConfig(host){return function(dispatch){dispatch({type:SOAPBOX_CONFIG_REMEMBER_REQUEST,host:host});return KVStore.getItemOrError("soapbox_config:".concat(host)).then(function(soapboxConfig){dispatch({type:SOAPBOX_CONFIG_REMEMBER_SUCCESS,host:host,soapboxConfig:soapboxConfig});return soapboxConfig;}).catch(function(error){dispatch({type:SOAPBOX_CONFIG_REMEMBER_FAIL,host:host,error:error,skipAlert:true});});};};var fetchSoapboxConfig=function fetchSoapboxConfig(host){return function(dispatch){return dispatch(fetchSoapboxJson(host));};};/** Tries to remember the config from browser storage before fetching it */var loadSoapboxConfig=function loadSoapboxConfig(){return function(dispatch,getState){var host=getHost(getState());return dispatch(rememberSoapboxConfig(host)).then(function(){return dispatch(fetchSoapboxConfig(host));});};};var fetchSoapboxJson=function fetchSoapboxJson(host){return function(dispatch){return(// HOTFIX: include soapbox.json in build so it updates with sw.js
// do something better long term.
dispatch(importSoapboxConfig(require('custom/soapbox.json'),host)));};};// staticClient.get('/instance/soapbox.json').then(({ data }) => {
//   if (!isObject(data)) throw 'soapbox.json failed';
//   dispatch(importSoapboxConfig(data, host));
//   return data;
// }).catch(error => {
//   dispatch(soapboxConfigFail(error, host));
// });
var importSoapboxConfig=function importSoapboxConfig(soapboxConfig,host){if(!soapboxConfig.brandColor){soapboxConfig.brandColor='#0482d8';}return{type:SOAPBOX_CONFIG_REQUEST_SUCCESS,soapboxConfig:soapboxConfig,host:host};};var soapboxConfigFail=function soapboxConfigFail(error,host){return{type:SOAPBOX_CONFIG_REQUEST_FAIL,error:error,skipAlert:true,host:host};};// https://stackoverflow.com/a/46663081
// const isObject = (o: any) => o instanceof Object && o.constructor === Object;
export{SOAPBOX_CONFIG_REQUEST_SUCCESS,SOAPBOX_CONFIG_REQUEST_FAIL,SOAPBOX_CONFIG_REMEMBER_REQUEST,SOAPBOX_CONFIG_REMEMBER_SUCCESS,SOAPBOX_CONFIG_REMEMBER_FAIL,getSoapboxConfig,rememberSoapboxConfig,fetchSoapboxConfig,loadSoapboxConfig,fetchSoapboxJson,importSoapboxConfig,soapboxConfigFail};