import"core-js/modules/es.array.filter.js";import"core-js/modules/es.object.to-string.js";import"core-js/modules/es.array.flat-map.js";import"core-js/modules/es.array.unscopables.flat-map.js";import"core-js/modules/es.array.push.js";import{accountSchema}from'soapbox/schemas';import{filteredArray}from'soapbox/schemas/utils';import{QueryKeys}from'../../query-keys';import{importEntities}from'../index';/**
 * Import accounts to the global cache from a list of accounts.
 *
 * @param accounts - array of Accounts
 */function importAccounts(accounts){var parsedResult=filteredArray(accountSchema).safeParse(accounts);if(parsedResult.success){importEntities(QueryKeys.accounts.global,parsedResult.data);}}/**
 * Import accounts to the global cache from a list of statuses.
 *
 * In each account, there are three possible account to import:
 *  - account itself
 *  - "quote" status's account
 *  - "reblog" status's account
 *
 * @param statuses - array of Statuses
 */function importAccountsFromStatuses(statuses){var accountsToImport=statuses.flatMap(function(status){var _status$reblog,_status$quote;return[status.account,(_status$reblog=status.reblog)===null||_status$reblog===void 0?void 0:_status$reblog.account,(_status$quote=status.quote)===null||_status$quote===void 0?void 0:_status$quote.account];}).filter(Boolean);var parsedResult=filteredArray(accountSchema).safeParse(accountsToImport);if(parsedResult.success){importEntities(QueryKeys.accounts.global,parsedResult.data);}}/**
 * Import accounts to the global cache from a list of notifications.
 *
 * In each notification, there are three possible accounts to import:
 *  - account itself
 *  - "quote" status's account
 *  - "reblog" status's account
 *
 * @param notifications - array of Notifications
 */function importAccountsFromNotifications(notifications){var accountsToImport=notifications.flatMap(function(notification){var accounts=[notification.account];if('status'in notification){var _notification$status,_notification$status$,_notification$status2,_notification$status3;accounts.push((_notification$status=notification.status)===null||_notification$status===void 0?void 0:(_notification$status$=_notification$status.reblog)===null||_notification$status$===void 0?void 0:_notification$status$.account);accounts.push((_notification$status2=notification.status)===null||_notification$status2===void 0?void 0:(_notification$status3=_notification$status2.quote)===null||_notification$status3===void 0?void 0:_notification$status3.account);}return accounts;}).filter(Boolean);var parsedResult=filteredArray(accountSchema).safeParse(accountsToImport);if(parsedResult.success){importEntities(QueryKeys.accounts.global,parsedResult.data);}}export{importAccounts,importAccountsFromStatuses,importAccountsFromNotifications};