import"core-js/modules/es.array.map.js";import"core-js/modules/es.array.sort.js";import"core-js/modules/es.array.concat.js";/**
 * Status normalizer:
 * Converts API statuses into our internal format.
 * @see {@link https://docs.joinmastodon.org/entities/status/}
 */import{Map as ImmutableMap,List as ImmutableList,Record as ImmutableRecord,fromJS}from'immutable';import{normalizeAccount}from'soapbox/normalizers/account';import{normalizeAttachment}from'soapbox/normalizers/attachment';import{normalizeEmoji}from'soapbox/normalizers/emoji';import{normalizeMention}from'soapbox/normalizers/mention';import{accountSchema,cardSchema,groupSchema,pollSchema,tombstoneSchema}from'soapbox/schemas';// https://docs.joinmastodon.org/entities/status/
export var StatusRecord=ImmutableRecord({account:null,application:null,approval_status:'approved',card:null,content:'',created_at:'',emojis:ImmutableList(),favourited:false,favourites_count:0,filtered:ImmutableList(),group:null,in_reply_to_account_id:null,in_reply_to_id:null,id:'',language:null,media_attachments:ImmutableList(),mentions:ImmutableList(),metrics:null,muted:false,pinned:false,pleroma:ImmutableMap(),poll:null,quote:null,quotes_count:0,reblog:null,reblogged:false,reblogs_count:0,replies_count:0,sensitive:false,spoiler_text:'',sponsored:false,tags:ImmutableList(),tombstone:null,uri:'',url:'',visibility:'public',// Internal fields
contentHtml:'',hidden:false,search_index:'',spoilerHtml:''});var normalizeStatusAccount=function normalizeStatusAccount(status){var account=status.get('account');if(account){return status.set('account',normalizeAccount(account));}else{return status;}};var normalizeAttachments=function normalizeAttachments(status){return status.update('media_attachments',ImmutableList(),function(attachments){return attachments.map(normalizeAttachment);});};var normalizeMentions=function normalizeMentions(status){return status.update('mentions',ImmutableList(),function(mentions){return mentions.map(normalizeMention);});};// Normalize emojis
var normalizeEmojis=function normalizeEmojis(entity){return entity.update('emojis',ImmutableList(),function(emojis){return emojis.map(normalizeEmoji);});};// Normalize the poll in the status, if applicable
var normalizeStatusPoll=function normalizeStatusPoll(status){try{var poll=pollSchema.parse(status.get('poll').toJS());return status.set('poll',poll);}catch(_e){return status.set('poll',null);}};var normalizeTombstone=function normalizeTombstone(status){try{var tombstone=tombstoneSchema.parse(status.get('tombstone').toJS());return status.set('tombstone',tombstone);}catch(_e){return status.set('tombstone',null);}};// Normalize card
var normalizeStatusCard=function normalizeStatusCard(status){try{var card=cardSchema.parse(status.get('card').toJS());return status.set('card',card);}catch(e){return status.set('card',null);}};// Fix order of mentions
var fixMentionsOrder=function fixMentionsOrder(status){var mentions=status.get('mentions',ImmutableList());var inReplyToAccountId=status.get('in_reply_to_account_id');// Sort the replied-to mention to the top
var sorted=mentions.sort(function(a,_b){if(a.get('id')===inReplyToAccountId){return-1;}else{return 0;}});return status.set('mentions',sorted);};// Add self to mentions if it's a reply to self
var addSelfMention=function addSelfMention(status){var accountId=status.getIn(['account','id']);var isSelfReply=accountId===status.get('in_reply_to_account_id');var hasSelfMention=accountId===status.getIn(['mentions',0,'id']);if(isSelfReply&&!hasSelfMention&&accountId){var mention=normalizeMention(status.get('account'));return status.update('mentions',ImmutableList(),function(mentions){return ImmutableList([mention]).concat(mentions);});}else{return status;}};// Move the quote to the top-level
var fixQuote=function fixQuote(status){return status.withMutations(function(status){status.update('quote',function(quote){return quote||status.getIn(['pleroma','quote'])||null;});status.update('quotes_count',function(quotes_count){return quotes_count||status.getIn(['pleroma','quotes_count'],0);});try{status.deleteIn(['pleroma','quote']);status.deleteIn(['pleroma','quotes_count']);}catch(_e){status.set('pleroma',ImmutableMap());}});};/** If the status contains spoiler text, treat it as sensitive. */var fixSensitivity=function fixSensitivity(status){if(status.get('spoiler_text')){status.set('sensitive',true);}};/** Rewrite `<p></p>` to empty string. */var fixContent=function fixContent(status){if(status.get('content')==='<p></p>'){return status.set('content','');}else{return status;}};var normalizeFilterResults=function normalizeFilterResults(status){return status.update('filtered',ImmutableList(),function(filterResults){return filterResults.map(function(filterResult){return filterResult.getIn(['filter','title']);});});};var parseAccount=function parseAccount(status){try{var account=accountSchema.parse(status.get('account').toJS());return status.set('account',account);}catch(_e){return status.set('account',null);}};var parseGroup=function parseGroup(status){try{var group=groupSchema.parse(status.get('group').toJS());return status.set('group',group);}catch(_e){return status.set('group',null);}};export var normalizeStatus=function normalizeStatus(status){return StatusRecord(ImmutableMap(fromJS(status)).withMutations(function(status){normalizeStatusAccount(status);normalizeAttachments(status);normalizeMentions(status);normalizeEmojis(status);normalizeStatusPoll(status);normalizeStatusCard(status);fixMentionsOrder(status);addSelfMention(status);fixQuote(status);fixSensitivity(status);fixContent(status);normalizeFilterResults(status);normalizeTombstone(status);parseAccount(status);parseGroup(status);}));};