import"core-js/modules/es.symbol.js";import"core-js/modules/es.object.get-own-property-descriptor.js";import"core-js/modules/es.array.push.js";import"core-js/modules/es.object.get-own-property-descriptors.js";import"core-js/modules/es.object.define-properties.js";import"core-js/modules/es.object.define-property.js";import _defineProperty from"@babel/runtime/helpers/esm/defineProperty";import _objectDestructuringEmpty from"@babel/runtime/helpers/esm/objectDestructuringEmpty";function ownKeys(object,enumerableOnly){var keys=Object.keys(object);if(Object.getOwnPropertySymbols){var symbols=Object.getOwnPropertySymbols(object);enumerableOnly&&(symbols=symbols.filter(function(sym){return Object.getOwnPropertyDescriptor(object,sym).enumerable;})),keys.push.apply(keys,symbols);}return keys;}function _objectSpread(target){for(var i=1;i<arguments.length;i++){var source=null!=arguments[i]?arguments[i]:{};i%2?ownKeys(Object(source),!0).forEach(function(key){_defineProperty(target,key,source[key]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(target,Object.getOwnPropertyDescriptors(source)):ownKeys(Object(source)).forEach(function(key){Object.defineProperty(target,key,Object.getOwnPropertyDescriptor(source,key));});}return target;}import"core-js/modules/es.object.assign.js";import"core-js/modules/es.json.stringify.js";import"core-js/modules/es.array.filter.js";import"core-js/modules/es.object.to-string.js";import"core-js/modules/es.object.keys.js";import"core-js/modules/es.array.includes.js";import"core-js/modules/es.string.includes.js";import"core-js/modules/web.dom-collections.for-each.js";import"core-js/modules/es.array.find.js";import"core-js/modules/es.number.constructor.js";var LOCAL_STORAGE_VERIFICATION_KEY='soapbox:verification';/**
 * Fetch the state of the user's verification in local storage.
 */var fetchStoredVerification=function fetchStoredVerification(){try{return JSON.parse(localStorage.getItem(LOCAL_STORAGE_VERIFICATION_KEY));}catch(_unused){return null;}};/**
 * Remove the state of the user's verification from local storage.
 */var removeStoredVerification=function removeStoredVerification(){localStorage.removeItem(LOCAL_STORAGE_VERIFICATION_KEY);};/**
 * Fetch and return the Registration token for Pepe.
 */var fetchStoredToken=function fetchStoredToken(){try{var verification=fetchStoredVerification();return verification.token;}catch(_unused2){return null;}};/**
 * Fetch and return the Registration timestamp for Pepe.
 */var fetchStoredTimestamp=function fetchStoredTimestamp(){try{var verification=fetchStoredVerification();if(typeof verification.timestamp==='number'){return new Date(verification.timestamp);}return null;}catch(_unused3){return null;}};/**
 * Fetch and return the state of the verification challenges.
 */var fetchStoredChallenges=function fetchStoredChallenges(){try{var verification=fetchStoredVerification();return verification.challenges;}catch(_unused4){return null;}};/**
 * Fetch and return the state of the verification challenge types.
 */var fetchStoredChallengeTypes=function fetchStoredChallengeTypes(){try{var verification=fetchStoredVerification();return verification.challengeTypes;}catch(_unused5){return null;}};/**
 * Update the verification object in local storage.
 *
 * @param {*} verification object
 */var updateStorage=function updateStorage(_ref){var updatedVerification=Object.assign({},(_objectDestructuringEmpty(_ref),_ref));var verification=fetchStoredVerification();localStorage.setItem(LOCAL_STORAGE_VERIFICATION_KEY,JSON.stringify(_objectSpread(_objectSpread({},verification),updatedVerification)));};/**
 * Save the challenges in localStorage.
 *
 * - If the API removes a challenge after the client has stored it, remove that
 *    challenge from localStorage.
 * - If the API adds a challenge after the client has stored it, add that
 *    challenge to localStorage.
 * - Don't overwrite a challenge that has already been completed.
 * - Update localStorage to the new set of challenges.
 */function saveChallenges(challenges){var currentChallenges=fetchStoredChallenges()||{};// Safely migrate to realuser.
// Can be removed after December 2023.
if(currentChallenges.captcha){currentChallenges.realuser=currentChallenges.captcha;delete currentChallenges.captcha;}var challengesToRemove=Object.keys(currentChallenges).filter(function(currentChallenge){return!challenges.includes(currentChallenge);});challengesToRemove.forEach(function(challengeToRemove){return delete currentChallenges[challengeToRemove];});for(var i=0;i<challenges.length;i++){var challengeName=challenges[i];if(typeof currentChallenges[challengeName]!=='number'){currentChallenges[challengeName]=0;}}updateStorage({challenges:currentChallenges,challengeTypes:challenges});}/**
 * Finish a challenge.
 */function finishChallenge(challenge){var currentChallenges=fetchStoredChallenges()||{};// Set challenge to "complete"
currentChallenges[challenge]=1;updateStorage({challenges:currentChallenges});}/**
 * Fetch the next challenge
 */var fetchNextChallenge=function fetchNextChallenge(){var currentChallenges=fetchStoredChallenges()||{};return Object.keys(currentChallenges).find(function(challenge){return currentChallenges[challenge]===0;});};/**
 * Check to see whether this registration session is expired, which means
 * it was created more than 48 hours ago.
 * @param date Date
 * @returns boolean
 */var isExpired=function isExpired(date){var currentTimeInMilliseconds=Date.now();var dateInMilliseconds=Number(date);// Get the difference between the given date and the current time in hours
var hoursDifference=(currentTimeInMilliseconds-dateInMilliseconds)/(1000*60*60);// Compare the difference with 48 hours
if(hoursDifference>48){return true;}else{return false;}};export{isExpired,removeStoredVerification,fetchStoredChallenges,fetchStoredToken,fetchStoredTimestamp,fetchStoredVerification,fetchStoredChallengeTypes,finishChallenge,fetchNextChallenge,saveChallenges,updateStorage};